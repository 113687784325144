<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Selecciona el país en el que naciste
      </div>
    </div>

    <ValidationObserver ref="formstepPiF" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">

      <q-form 
        ref="myFormPiF"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPifReset"
        class="formDetailZone "
      >

        <div class="dataSection_container">
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
              <div class="col col-xs-12 col-md-6 canna_forma_campo_container" style="padding-bottom: 0px;"
                :style=" ( is_Mobile === true || $q.screen.lt.md ) ? '':' max-width:300px !important;'" 
              ><!-- padding-left: 8px; -->

                <!-- Campo de Jornada (select) -->
                <ValidationProvider 
                  ref="fldx_pais" name="pais" :immediate="false"
                  :rules=" { required: true, oneOf: z_allowPaises } " 
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated}">País de nacimiento</div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-select
                      name="pais"
                      v-model="frmData.pais"
                      filled
                      use-input
                      :dense="true"
                      :label="(frmData.pais ==='' || frmData.pais === null) ? 'Elige una opción' : ''"
                      :options="paisesOptions"
                      option-value="id"
                      option-label="descripcion"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('pais','País de nacimiento').replace('El campo','') : '' "
                      @filter="filterPaisFn"
                    >
                      <!-- paisesOptions  cat_Paises.dataCatalog -->
                      <template v-slot:append>
                        <q-icon v-if="frmData.pais !== null" class="cursor-pointer" name="clear" @click.stop="frmData.pais = null" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.descripcion}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>

                    </q-select>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

export default {
  name: 'psi_screen_horariojornada',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-------------
      zloadingData: false,
      processingData: false,
      //-----------
      z_allowPaises: [],
      profileRecords:[], //Los registros previamente guardados en este perfil (solo uno)
      paisesOptions: [],
      frmDataOriginal: {
        pais: null
      },
      frmData: {
        pais: null
      }
    }
  },
  computed: {
    cat_Paises () { // catalogo id = 27 País de nacimiento
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(27) : this.getHelperCat(27)
    },
    mainErrors () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formstepPiF) {
            if (this.$refs.formstepPiF.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiF.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiF.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_pais -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/direccion-a'
      this.$cannaDebug('-- psiPage_pais -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPifReset (){ 

    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_pais -- do Submit Data --- ')
      var test = 11 
      var toAdd = [], toRemove = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 && this.frmData.pais!==null) { // Que existan valores...!

        // Pais
        if (testA._.pais.status === 'MODIFIED') {
          var item = testA._.pais
            if (item.status === 'ADDED') {
              toAdd.push(item.current)
            } else if (item.status === 'MODIFIED') {
              if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
                toRemove.push(item.original)
              }
              toAdd.push(item.current)
            } else if (item.status === 'DELETED') {
              toRemove.push(item.original)
            }
        }
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')
        // ----------------------
        // Datos a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'picDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // Datos para agregar
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        // -----------
        allowNextPage = true
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.PAIS ){ //< 18 
        await this.hpb_updateProfileStatus( this.zstatUser.PAIS ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/direccion-c'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { categoria_id: '27', 'per-page': 'all', expand:'catalogo'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'pifData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = { pais: null }
        this.frmData = { pais: null }
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var dt_pais = null
        profileData.forEach(itemP => {
          dt_pais = itemP.catalogo_id
        })
        var newData = { pais: dt_pais }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))
      } else { // No hay info todavia 
        this.frmDataOriginal = { pais: null }
        this.frmData = { pais: null }
      }
      return true 
    },
    //---- Utileria para el select 
    filterPaisFn ( val, update ){ 
      //cat_Paises.dataCatalog  X   descripcion
      if (val === '') {
        update(() => {
          this.paisesOptions = this.cat_Paises.dataCatalog
          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        this.paisesOptions = this.cat_Paises.dataCatalog.filter(v => v.descripcion.toLowerCase().indexOf(needle) > -1)
      })
    }
  },
  beforeCreate () {
    // this.$cannaDebug('-- psiPage_pais -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_pais -- created')
    this.zinitScreen = false 
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_pais --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_pais -- mounted')
    this.zloadingData = true
    this.showLoadx(true, 2)

    if (this.$refs.fldx_pais){
      this.$refs.fldx_pais.reset()
    }
    if (this.getHelperCat(27).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (27, null).then( respx => { 
        this.$cannaDebug('-- psiPage_sueldos --   categoria 27 pais finish ')
        testCatA = respx 
      })
    }
    await this._waitRequestProc(200)
    var paisesVals = []
    this.getHelperCat(27).dataCatalog.forEach( item => { 
      paisesVals.push(item.id)
    })
    this.z_allowPaises = paisesVals
    
    await this._waitRequestProc(200)
    var respInitData = null
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.paisesOptions = this.cat_Paises.dataCatalog
    this.$forceUpdate()
    if (this.$refs.fldx_pais){
      this.$refs.fldx_pais.reset()
    }
    this.zloadingData = false
    this.showLoadx(false, 2)

    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_pais -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_pais -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_pais -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      await this._waitRequestProc(200)
      var respInitData = null
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.paisesOptions = this.cat_Paises.dataCatalog
      await this._waitRequestProc(200)
      this.$forceUpdate()
      if (this.$refs.fldx_pais){
        this.$refs.fldx_pais.reset()
      }
      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_pais -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_pais -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_pais -- destroyed')
  }
}
</script>
